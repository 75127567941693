import ProductStock from './ProductStock.vue'
import ProductStockCreate from './Create.vue'
import ProductStockEdit from './Create.vue'
import ProductStockShow from './Show.vue'

export default [
    {
        path: '/product_stock',
        name: 'ProductStock',
        component: ProductStock
    },
    {
        path: '/product_stock/create',
        name: 'ProductStockCreate',
        component: ProductStockCreate
    },
    {
        path: '/product_stock/edit/:id',
        name: 'ProductStockEdit',
        component: ProductStockEdit
    },
    {
        path: '/product_stock/show/:id',
        name: 'ProductStockShow',
        component: ProductStockShow
    },
]
