<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col">
              <div class="card card-statistic-1">
                <div class="card-icon bg-success">
                  <i class="fas fa-wine-bottle"></i>
                </div>
                <div class="card-wrap">
                  <div class="card-header">
                    <h4>Stock Left</h4>
                  </div>
                  <div class="card-body">
                    {{ productStock.stock_left }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-statistic-1">
                <div class="card-icon bg-warning">
                  <i class="fas fa-wine-bottle"></i>
                </div>
                <div class="card-wrap">
                  <div class="card-header">
                    <h4>Stock Uses</h4>
                  </div>
                  <div class="card-body">
                    {{ productStock.stock_uses }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-statistic-1">
                <div class="card-icon bg-danger">
                  <i class="fas fa-wine-bottle"></i>
                </div>
                <div class="card-wrap">
                  <div class="card-header">
                    <h4>Stock Uses Last Week</h4>
                  </div>
                  <div class="card-body">
                    {{ productStock.stock_uses_last_week }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card card-statistic-1">
                <div class="card-icon bg-primary">
                  <i class="fas fa-wine-bottle"></i>
                </div>
                <div class="card-wrap">
                  <div class="card-header">
                    <h4>Stock Uses To Day</h4>
                  </div>
                  <div class="card-body">
                    {{ productStock.stock_uses_to_day }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4>Filter</h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label>Select Product</label>
                <model-list-select
                  :list="products"
                  v-model="filter.product_id"
                  option-value="id"
                  option-text="name"
                  placeholder="Select Product"
                >
                </model-list-select>
              </div>
              <div class="form-group">
                <label>Select Venue</label>
                <model-list-select
                  :list="venues"
                  v-model="filter.venue_id"
                  option-value="id"
                  option-text="title"
                  placeholder="Select Venue"
                >
                </model-list-select>
              </div>
            </div>
            <div class="card-footer text-right">
              <button
                class="btn btn-primary mr-1"
                :disabled="isSubmitting"
                @click="getData"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h4>History {{ productStock.product_name }} Stock Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <!-- <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductStockCreate' }"
                    >Create</router-link
                  > -->
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="productStock.product_stock"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.created_at_diff }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ProductShow',
                          params: { id: row.item.product_id },
                        }"
                      >
                        {{ row.item.name_product }}
                      </router-link>
                    </td>
                    <td>{{ row.item.session_number }}</td>
                    <td>{{ row.item.created_by_name }}</td>
                    <td>{{ row.item.name_venue }}</td>
                    <td>{{ row.item.status }}</td>
                    <td>{{ row.item.stock }}/{{ row.item.name_unit }}</td>
                    <td>{{ row.item.request_status }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :total-visible="7"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h4>History {{ productStock.product_name }} Stock Venue Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search2"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers2"
                :items="productStock.product_per_venue"
                item-key="venue_id"
                :search="search2"
                hide-default-footer
                :page.sync="page2"
                :items-per-page="itemsPerPage2"
                class="elevation-1"
                @page-count="pageCount2 = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.venue_name }}</td>
                    <td>{{ row.item.stock }}/{{ row.item.unit_name }},</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page2"
                    :length="pageCount2"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { apiGet } from "../../services/api";

export default {
  name: "ProductStock",
  data() {
    return {
      search: "",
      search2: "",
      page: 1,
      page2: 1,
      pageCount: 0,
      pageCount2: 0,
      itemsPerPage: 5,
      itemsPerPage2: 5,
      name: "Product Stock",
      productStock: [],
      products: [],
      venues: [],
      loading: false,
      isSubmitting: false,
      filter: {
        product_id: "",
        venue_id: "",
      },
    };
  },
  mounted() {
    this.getProduct();
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.isSubmitting = true;
      axios
        .get(this.$store.state.api + "product_stock", {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            product_id: this.filter.product_id,
            venue_id: this.filter.venue_id,
          },
        })
        .then((result) => {
          this.loading = false;
          this.isSubmitting = false;
          this.productStock = result.data.data;
        })
        .catch((err) => {
          this.isSubmitting = false;
          console.log(err);
        });
    },
    getProduct() {
      apiGet("product/all/minimal")
        .then((result) => {
          this.products = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      apiGet("venue")
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Created At",
          value: "created_at_diff",
        },
        {
          text: "Product",
          value: "name_product",
        },
        {
          text: "Session Number",
          value: "session_number",
        },
        {
          text: "Created By",
          value: "created_by_name",
        },
        {
          text: "Venue",
          value: "name_venue",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Stock",
          value: "stock",
        },
        {
          text: "Request Status",
          value: "request_status",
        },
      ];
    },
    headers2() {
      return [
        {
          text: "Venue",
          value: "venue_name",
        },
        {
          text: "Stock",
          value: "stock",
        },
      ];
    },
  },
};
</script>
